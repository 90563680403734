#upload-new-image-tab {
    .crop-container {
        min-height: 280px;
        width: auto;
        position: relative;

        .reactEasyCrop_CropArea {
            border: 1px solid rgba(255, 255, 255, 0.9);
            color: rgba(0, 0, 0, 0.7);
        }
    }
    .controls {
        text-align: center;
        margin: 0 auto;
        width: 200px;
    }
}

.nav-link {
    cursor: pointer;
}

.tab-content {
    .tab-pane {
        padding: 10px 0;
    }

    .reject-reason {
        font-weight: 500;
        font-size: 14px;
    }
}